import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { serialize } from "object-to-formdata";

const initialState = {
  department: [],
  oneDepartment: [],

  statusUpdateDepartment: "idle",
  errorUpdateDepartment: null,

  statusDepartment: "idle",
  errorDepartment: null,

  deleteDepartmentStatus: "idle",
  ErrorDeleteDepartment: null,

  status: "idle",
  error: null,
};
export const fetchDepartment = createAsyncThunk(
  "departments/fetchDepartment",

  async () => {
    let data;
    try {
      const response = await axios.get(`/departments`);
      const data = await response.data;
      if ((response.status = 200)) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteDepartment = createAsyncThunk(
  "departments/deleteDepartment",

  async (id, thunkAPI) => {
    try {
      const response = await axios.patch(`/departments/delete/${id}`);
      const data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchDepartment());
        return {
          data: data,
          message: " SUCCESS ! Department  has been deleted. ",
        };
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const fetchOneDepartment = createAsyncThunk(
  "departments/fetchOneDepartment",

  async (id) => {
    let data;
    try {
      const response = await axios.get(`/departments/${id}`);
      const data = await response.data;

      if ((response.status = 200)) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const createDepartment = createAsyncThunk(
  "departments/createdepartments",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/departments`,
        serialize(body, { indices: true })
      );

      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchDepartment());
        return { data: data, message: "Department  is successfully added" };
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const updateDepartment = createAsyncThunk(
  "departments/updateDepartment",
  async (body, thunkAPI) => {
    const { id, name, img, description } = body;
    let data;
    try {
      const response = await axios.patch(
        `/departments/${id}`,
        serialize({ name, img, description }, { indices: true })
      );
      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchDepartment());
        return {
          data: data,
          message: "Department has been updated  successfully !",
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

const slice = createSlice({
  name: "department",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDepartment.pending]: (state) => {
      state.status = "loading";
    },

    [fetchDepartment.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.department = action.payload;
    },
    [fetchDepartment.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [fetchOneDepartment.pending]: (state) => {
      state.status = "loading";
    },

    [fetchOneDepartment.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.oneDepartment = action.payload;
    },

    [fetchOneDepartment.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [createDepartment.pending]: (state) => {
      state.statusDepartment = "loading";
    },

    [createDepartment.fulfilled]: (state, action) => {
      state.statusDepartment = "succeeded";
    },

    [createDepartment.rejected]: (state, action) => {
      state.statusDepartment = "failed";
      state.errorDepartment = action.error.message;
    },

    [updateDepartment.pending]: (state) => {
      state.statusUpdateDepartment = "loading";
    },

    [updateDepartment.fulfilled]: (state, action) => {
      state.statusUpdateDepartment = "succeeded";
    },

    [updateDepartment.rejected]: (state, action) => {
      state.statusUpdateDepartment = "failed";
      state.errorUpdateDepartment = action.error.message;
    },

    [deleteDepartment.pending]: (state) => {
      state.deleteDepartmentStatus = "loading";
    },

    [deleteDepartment.fulfilled]: (state) => {
      state.deleteDepartmentStatus = "succeeded";
    },

    [deleteDepartment.rejected]: (state, action) => {
      state.deleteDepartmentStatus = "failed";
      state.ErrorDeleteDepartment = action.error.message;
    },
  },
});

export const reducer = slice.reducer;
export default slice;
